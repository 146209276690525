<template>
    <BT-Blade-Item
        bladeName="receival-archive"
        :bladesData="bladesData"
        :canDelete="false"
        :canEdit="false"
        navigation="receival-archives"
        :onPullSuccessAsync="pullReceival"
        title="Archived Receival">
        <template v-slot="{ item }">
            <v-row no-gutters>
                <v-col cols="12" class="d-flex justify-center my-4">
                    <BT-Avatar v-model="item.sellerID" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Entity 
                        navigation="public-locations"
                        :itemValue="item.departureLocationID"
                        single
                        useLocalCache>
                        <template v-slot="{ item }">
                            <BT-Field-Address
                                label="FROM"
                                :value="item" />
                        </template>
                    </BT-Entity>
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Entity 
                        navigation="public-locations"
                        :itemValue="item.destinationLocationID"
                        single
                        useLocalCache>
                        <template v-slot="{ item }">
                            <BT-Field-Address
                                label="TO"
                                :value="item" />
                        </template>
                    </BT-Entity>
                </v-col>

                <!-- <v-col cols="6" sm="4">
                    <BT-Entity
                        navigation="public-companies"
                        :itemValue="item.courierID">
                        <template v-slot="{ item }">
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-subtitle>COURIER</v-list-item-subtitle>
                                    <v-list-item-title>{{ item.companyName }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </BT-Entity>
                </v-col> -->

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        v-if="item.deliveredOn != null"
                        v-model="item.deliveredOn"
                        label="Delivered On"
                        shortDateAndTime />
                    <BT-Field-Date
                        v-else-if="item.dueArrivalOn != null"
                        v-model="item.dueArrivalOn"
                        label="Due On" />
                </v-col>

                <v-col v-if="item.deliveredByUserID != null" cols="6" sm="4">
                    <BT-Entity 
                        navigation="public-users"
                        :itemValue="item.deliveredByUserID"
                        single
                        useLocalCache>
                        <template v-slot="{ item }">
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Driver</v-list-item-subtitle>
                                    <v-list-item-title>{{ item | toUserLine }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </BT-Entity>
                </v-col>
<!-- 
                <v-col cols="6" sm="4">
                    <v-list-item dense>
                        <v-list-item-content>
                            <v-list-item-subtitle>Signer</v-list-item-subtitle>
                            <v-list-item-title>{{ item.receiverName }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                 -->
                <v-col cols="6" sm="4">
                    <v-list-item dnese>
                        <v-list-item-content>
                            <v-list-item-subtitle>Signer: {{ item.receiverName }}</v-list-item-subtitle>
                            <BT-Signature
                                class="my-1"
                                height="50px"
                                v-model="item.proofOfDelivery" />
                        </v-list-item-content>
                    </v-list-item>
                </v-col>

                <v-col v-if="item.receivedByUserID != null" cols="6" sm="4">
                    <BT-Entity 
                        navigation="public-users"
                        :itemValue="item.receivedByUserID"
                        single
                        useLocalCache>
                        <template v-slot="{ item }">
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Receiver</v-list-item-subtitle>
                                    <v-list-item-title>{{ item | toUserLine }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </BT-Entity>
                </v-col>
            </v-row>

            <v-expansion-panels v-model="panelV">
                <BT-Blade-Expansion-Items
                    :canExportCSV="false"
                    :canRefresh="false"
                    :canSelect="false"
                    :headers="[
                        { text: 'Package', value: 'measurement', display: true, title: 1 },
                        { text: 'Delivered', value: 'quantityDelivered', textFilter: 'toDisplayNumber', subtitle: 3, prefix: 'Delivered: ' },
                        { text: 'Received', value: 'quantityReceived', textFilter: 'toDisplayNumber', subtitle: 4, prefix: 'Received: ' }]"
                    hideActions
                    :items="item.packagedItems"
                    title="Packages">
                    <template v-slot:measurement="{ item }">
                        <span v-if="item.measurementStandard != null">{{ item.measurementStandard }}</span>
                        <BT-Entity
                            v-else
                            navigation="measurements"
                            :itemValue="item.measurementID"
                            itemText="measurementName" />
                    </template>
                    <template v-slot:listItem="{ item }">
                        <v-list-item-content>
                            <v-list-item-title>
                                <span v-if="item.measurementStandard != null">{{ item.measurementStandard }}</span>
                                <BT-Entity
                                    v-else
                                    navigation="measurements"
                                    :itemValue="item.measurementID"
                                    itemText="measurementName" />
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ item | toMeasurementLine }}</v-list-item-subtitle>
                            <v-list-item-subtitle>Delivered: {{ item.quantityDelivered | toDisplayNumber }} | Received: {{ item.quantityReceived | toDisplayNumber }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </BT-Blade-Expansion-Items>
                <!-- <BT-Blade-Expansion-Items
                    :canSelect="false"
                    :canRefresh="false"
                    :headers="[
                        { text: 'Name', value: 'measurement', display: true, title: 1 },
                        { text: 'Delivered', value: 'quantityDelivered', textFilter: 'toDisplayNumber', subtitle: 1, prefix: 'Delivered: ' },
                        { text: 'Received', value: 'quantityReceived', textFilter: 'toDisplayNumber', subtitle: 2, prefix: 'Received: ' }]"
                    hideActions
                    :items="item.packagedItems"
                    title="Packages">
                    <template v-slot:measurement="{ item }">
                        <BT-Entity 
                            navigation="measurements"
                            :itemValue="item.measurementID"
                            itemText="measurementName" />
                    </template>
                </BT-Blade-Expansion-Items> -->
            </v-expansion-panels>

            
        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';
import { measurementArea } from '~helpers';

export default {
    name: 'Receival-Archive-Blade',
    components: {
        BTSignature: () => import('~components/BT-Signature.vue'),
        // BTSignatureOverlay: () => import('~components/BT-Signature-Overlay.vue')
    },
    data: function() {
        return {
            panelV: 0
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        pullReceival(release) {
            if (this.isLengthyArray(release.packagedItems)) {
                release.packagedItems.sort(firstBy(x => measurementArea(x)));
            }

            return release;
        }
    }
}
</script>