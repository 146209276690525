var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Item',{attrs:{"bladeName":"receival-archive","bladesData":_vm.bladesData,"canDelete":false,"canEdit":false,"navigation":"receival-archives","onPullSuccessAsync":_vm.pullReceival,"title":"Archived Receival"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center my-4",attrs:{"cols":"12"}},[_c('BT-Avatar',{model:{value:(item.sellerID),callback:function ($$v) {_vm.$set(item, "sellerID", $$v)},expression:"item.sellerID"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('BT-Entity',{attrs:{"navigation":"public-locations","itemValue":item.departureLocationID,"single":"","useLocalCache":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('BT-Field-Address',{attrs:{"label":"FROM","value":item}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('BT-Entity',{attrs:{"navigation":"public-locations","itemValue":item.destinationLocationID,"single":"","useLocalCache":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('BT-Field-Address',{attrs:{"label":"TO","value":item}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[(item.deliveredOn != null)?_c('BT-Field-Date',{attrs:{"label":"Delivered On","shortDateAndTime":""},model:{value:(item.deliveredOn),callback:function ($$v) {_vm.$set(item, "deliveredOn", $$v)},expression:"item.deliveredOn"}}):(item.dueArrivalOn != null)?_c('BT-Field-Date',{attrs:{"label":"Due On"},model:{value:(item.dueArrivalOn),callback:function ($$v) {_vm.$set(item, "dueArrivalOn", $$v)},expression:"item.dueArrivalOn"}}):_vm._e()],1),(item.deliveredByUserID != null)?_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('BT-Entity',{attrs:{"navigation":"public-users","itemValue":item.deliveredByUserID,"single":"","useLocalCache":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Driver")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("toUserLine")(item)))])],1)],1)]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-list-item',{attrs:{"dnese":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Signer: "+_vm._s(item.receiverName))]),_c('BT-Signature',{staticClass:"my-1",attrs:{"height":"50px"},model:{value:(item.proofOfDelivery),callback:function ($$v) {_vm.$set(item, "proofOfDelivery", $$v)},expression:"item.proofOfDelivery"}})],1)],1)],1),(item.receivedByUserID != null)?_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('BT-Entity',{attrs:{"navigation":"public-users","itemValue":item.receivedByUserID,"single":"","useLocalCache":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Receiver")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("toUserLine")(item)))])],1)],1)]}}],null,true)})],1):_vm._e()],1),_c('v-expansion-panels',{model:{value:(_vm.panelV),callback:function ($$v) {_vm.panelV=$$v},expression:"panelV"}},[_c('BT-Blade-Expansion-Items',{attrs:{"canExportCSV":false,"canRefresh":false,"canSelect":false,"headers":[
                        { text: 'Package', value: 'measurement', display: true, title: 1 },
                        { text: 'Delivered', value: 'quantityDelivered', textFilter: 'toDisplayNumber', subtitle: 3, prefix: 'Delivered: ' },
                        { text: 'Received', value: 'quantityReceived', textFilter: 'toDisplayNumber', subtitle: 4, prefix: 'Received: ' }],"hideActions":"","items":item.packagedItems,"title":"Packages"},scopedSlots:_vm._u([{key:"measurement",fn:function(ref){
                        var item = ref.item;
return [(item.measurementStandard != null)?_c('span',[_vm._v(_vm._s(item.measurementStandard))]):_c('BT-Entity',{attrs:{"navigation":"measurements","itemValue":item.measurementID,"itemText":"measurementName"}})]}},{key:"listItem",fn:function(ref){
                        var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[(item.measurementStandard != null)?_c('span',[_vm._v(_vm._s(item.measurementStandard))]):_c('BT-Entity',{attrs:{"navigation":"measurements","itemValue":item.measurementID,"itemText":"measurementName"}})],1),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("toMeasurementLine")(item)))]),_c('v-list-item-subtitle',[_vm._v("Delivered: "+_vm._s(_vm._f("toDisplayNumber")(item.quantityDelivered))+" | Received: "+_vm._s(_vm._f("toDisplayNumber")(item.quantityReceived)))])],1)]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }